import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useApprovalList() {
  // Use toast
  const toast = useToast()

  const refApprovalListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // // { key: 'No', sortable: true },
    // { key: 'nik', label: 'Nama Proyek', sortable: true },
    // { key: 'nama', label: 'Sales', sortable: true },
    // { key: 'bu', label: 'Jenis Input', sortable: true },
    // { key: 'divisi', label: 'Diskon Diminta (%)', sortable: true },
    // { key: 'lvel', label: 'Tgl Pengajuan', sortable: true },
    // { key: 'batas_bawah', label: 'Periode Delivery', sortable: true },
    // { key: 'batas_atas', label: 'Premi', sortable: true},
    // { key: 'df', label: 'Mitra Usaha', sortable: true},    
    // { key: 'program', label: 'Program DF', sortable: true},
    // { key: 'status', label: 'Status', sortable: true},
    // { key: 'Actions', sortable: true },

    { key: 'trn_code', label: 'Kode Pengajuan', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
    { key: 'trn_name_project', label: 'Nama Proyek', sortable: true },
    { key: 'created_by', label: 'Sales', sortable: true },
    { key: 'trn_type', label: 'Jenis Input', sortable: true },
    { key: 'trn_diskon_request', label: 'Diskon Diminta (%)', sortable: true },
    { key: 'trn_date_request', label: 'Tgl Pengajuan', sortable: true },
    { key: 'trn_periode', label: 'Periode Delivery', sortable: true },
    { key: 'trn_premi', label: 'Premi', sortable: true },
    { key: 'trn_mitrausaha', label: 'Kode MU', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
    { key: 'trn_mitrausaha_name', label: 'Mitra Usaha', sortable: true},
    { key: 'trn_program_df', label: 'Program DF', sortable: true},
    { key: 'trn_flag', label: 'Kode Status', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
    // { key: 'trn_flag_name', label: 'Status', sortable: true},
    { key: 'trn_flag_status', label: 'Status', sortable: true},
    { key: 'Actions', sortable: true },
  ]
  const perPage = ref(10)
  const totalApproval = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25]
  const searchQuery = ref('')
  const sortBy = ref('trn_date_request')
  const isSortDirDesc = ref(true)
  // const roleFilter = ref(null)
  // const planFilter = ref(null)
  // const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refApprovalListTable.value ? refApprovalListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalApproval.value,
    }
  })

  const refetchData = () => {
    refApprovalListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => { //roleFilter, planFilter, statusFilter
    refetchData()
  })

  const fetchApproval = (ctx, callback) => {
    store
      .dispatch('app-approval/fetchApproval', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        emp_id: localStorage.getItem('emp_id'),
        // cs_category: roleFilter.value,
        // cs_status: planFilter.value,
        // cs_bu: localStorage.getItem('bisnis_unit'),

      })
      .then(response => {
        // const { approval, total } = response.data

        callback(response.data.data)
        totalApproval.value = response.data.count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Approval Pengajuan Sales',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveApprovalRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveApprovalRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveApprovalStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchApproval,
    tableColumns,
    perPage,
    currentPage,
    totalApproval,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refApprovalListTable,

    resolveApprovalRoleVariant,
    resolveApprovalRoleIcon,
    resolveApprovalStatusVariant,
    refetchData,

    // Extra Filters
    // roleFilter,
    // planFilter,
    // statusFilter,
  }
}
