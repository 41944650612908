<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
           <label>Nama Proyek</label>
             <b-form-input
              id="nama_proyek"
              placeholder="input Nama Proyek"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="roleFilter"
              :reduce="val => val.value"
              @input="(val) => $emit('update:roleFilter', val)"
            />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Tgl Pengajuan</label>
            <flat-pickr
             :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control"
              :config="{ dateFormat: 'Y-m-d'}"
              :value="proyekFilter"
              :reduce="val => val.value"
              @input="(val) => $emit('update:proyekFilter', val)"
              placeholder="YYYY-MM-DD"
            />
          </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Sales</label>
          <v-select
            placeholder="Pilih Sales"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="planFilter"
            :options="planOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:planFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Jenis Input</label>
          <v-select
            placeholder="Pilih Jenis Input"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Diskon Diminta</label>
          <v-select
            placeholder="Pilih Diskon Diminta"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="muFilter"
            :options="musOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:muFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Periode Delivery</label>
            <flat-pickr
             :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control"
              :config="{ dateFormat: 'Y-m-d'}"
              :value="periodeFilter"
              :reduce="val => val.value"
              @input="(val) => $emit('update:periodeFilter', val)"
              placeholder="YYYY-MM-DD"
            />
          </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Mitra Usaha</label>
          <v-select
            placeholder="Pilih Mitra Usaha"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="dfFilter"
            :options="muOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:dfFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Program DF</label>
          <v-select
            placeholder="Pilih Program DF"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="muFilter"
            :options="premisOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:premiFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Premi</label>
          <v-select
            placeholder="Pilih Premi"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="aktifFilter"
            :options="aktifOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:aktifFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    vSelect,
    flatPickr, 
  },
  props: {
    proyekFilter: {
      type: [String, null],
      default: null,
    },
    roleFilter: {
      type: [String, null],
      default: null,
    },
    planFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    periodeFilter: {
      type: [String, null],
      default: null,
    },
    muFilter: {
      type: [String, null],
      default: null,
    },
    dfFilter: {
      type: [String, null],
      default: null,
    },
    premiFilter: {
      type: [String, null],
      default: null,
    },
    aktifFilter: {
      type: [String, null],
      default: null,
    },
    proyekOptions: {
      type: Array,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    periodeOptions: {
      type: Array,
      required: true,
    },
    muOptions: {
      type: Array,
      required: true,
    },
    dfOptions: {
      type: Array,
      required: true,
    },
    premiOptions: {
      type: Array,
      required: true,
    },
    aktifOptions: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
